/**
 * Video playback on viewport
 */

export default (() => {
  const lazyVideos = document.querySelectorAll('.lazy-video')

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.25,
  }

  /* eslint-disable no-unused-vars */
  const videoObserver = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.paused) {
          entry.target.play()
        }
      } else {
        entry.target.pause()
        entry.target.currentTime = 0
      }
    })
  }, options)
  /* eslint-enable no-unused-vars */

  lazyVideos.forEach((video) => {
    videoObserver.observe(video)
  })
})()
